<template>
  <div class="row px-md-4">
    <div class="col-12 col-sm-12">
      <h2 class="main-title">Settings</h2>
    </div>
    <div class="row">
      <div
        class="col-6 col-sm-6 col-md-4 col-lg-3 mb-30px"
        v-for="(setting, index) in settings_menu"
        :key="index"
      >
        <router-link
          :to="`/settings/${setting.url}`"
          class="card settings-link text-center py-45px"
        >
          <div>
            <img
              :src="require(`@/assets/img/${setting.img}`)"
              :alt="`${setting.title} icon`"
              class="setting-icon normal mb-4"
            />
          </div>
          <h3 class="color-gray">{{ setting.title }}</h3>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data"],
  data() {
    return {
      settings_menu: [
        {
          url: "general",
          img: "general.svg",
          title: "General"
        },
        {
          url: "carriers",
          img: "carriers.svg",
          title: "Carriers"
        },
        {
          url: "users",
          img: "users.svg",
          title: "Users"
        },
        {
          url: "billing",
          img: "billing.svg",
          title: "Billing"
        },
        {
          url: "password",
          img: "password.svg",
          title: "Password"
        },
        {
          url: "monthly-tips",
          img: "monthly-tips.svg",
          title: "Monthly Tips"
        },
        {
          url: "upload-logo",
          img: "upload-logo.svg",
          title: "Upload Logo"
        },
        {
          url: "report-distribution",
          img: "repor-distribution.svg",
          title: "Report Distribution"
        }
      ]
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }
  }
};
</script>
